define("shared/components/gke-node-pool-row/component", ["exports", "semver", "shared/utils/util", "shared/components/gke-node-pool-row/template"], function (_exports, _semver, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    google: Ember.inject.service(),
    serviceVersions: Ember.inject.service('version-choices'),
    layout: _template.default,
    cluster: null,
    originalCluster: null,
    nodePool: null,
    nodeAdvanced: false,
    oauthScopesSelection: null,
    scopeConfig: null,
    diskTypeContent: null,
    imageTypeContent: null,
    machineTypes: null,
    nodeVersions: null,
    clusterVersion: null,
    upgradeVersion: false,
    originalPoolVersion: null,

    init() {
      this._super(...arguments);

      const {
        nodePool
      } = this;
      Ember.setProperties(this, {
        scopeConfig: {},
        diskTypeContent: this.google.diskTypes,
        imageTypeContent: this.google.imageTypesV2
      });

      if (nodePool) {
        if (!Ember.get(this, 'oauthScopesSelection')) {
          const oauthScopes = Ember.get(nodePool.config, 'oauthScopes');
          const {
            oauthScopesSelection,
            scopeConfig
          } = this.google.unmapOauthScopes(oauthScopes);
          Ember.set(this, 'oauthScopesSelection', oauthScopesSelection);

          if (scopeConfig) {
            Ember.set(this, 'scopeConfig', scopeConfig);
          }
        }

        if (nodePool.version) {
          Ember.set(this, 'originalPoolVersion', nodePool.version);
        }
      } else {
        Ember.setProperties(this, {
          oauthScopesSelection: this.google.oauthScopeOptions.DEFAULT,
          scopeConfig: this.google.defaultScopeConfig
        });
      }
    },

    actions: {
      setNodeLabels(section) {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        Ember.set(this, 'nodePool.config.labels', section);
      },

      updateScopes() {
        const oauthScopesSelection = Ember.get(this, 'oauthScopesSelection');
        const scopeConfig = Ember.get(this, 'scopeConfig');
        Ember.run.next(() => {
          Ember.set(this.nodePool.config, 'oauthScopes', this.google.mapOauthScopes(oauthScopesSelection, scopeConfig));
        });
      }

    },
    scopeSelectionsChanged: Ember.observer('oauthScopesSelection', function () {
      this.send('updateScopes');
    }),
    autoscalingChanged: Ember.observer('nodePool.autoscaling.enabled', function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      const {
        nodePool: {
          autoscaling
        }
      } = this;

      if (autoscaling !== null && autoscaling !== void 0 && autoscaling.enabled) {
        Ember.setProperties(this, {
          'nodePool.autoscaling.minNodeCount': 1,
          'nodePool.autoscaling.maxNodeCount': 3
        });
      } else {
        Ember.run.next(this, () => {
          if (this.isDestroyed || this.isDestroying) {
            return;
          }

          if (!Ember.isEmpty(autoscaling === null || autoscaling === void 0 ? void 0 : autoscaling.minNodeCount)) {
            Ember.set(this, 'nodePool.autoscaling.minNodeCount', null);
          }

          if (!Ember.isEmpty(autoscaling === null || autoscaling === void 0 ? void 0 : autoscaling.maxNodeCount)) {
            Ember.set(this, 'nodePool.autoscaling.maxNodeCount', null);
          }
        });
      }
    }),
    // if true, set np.version to latest version <= cp version
    // if false, revert np.version
    upgradeVersionChanged: Ember.observer('upgradeVersion', 'maxAvailableVersion', function () {
      const {
        upgradeVersion,
        originalPoolVersion,
        nodePool,
        maxAvailableVersion
      } = this;

      if (upgradeVersion) {
        Ember.set(nodePool, 'version', maxAvailableVersion);
      } else {
        Ember.set(nodePool, 'version', originalPoolVersion);
      }
    }),
    // if the pool is new, keep version in sync with cp version
    clusterVersionChanged: Ember.on('init', Ember.observer('clusterVersion', 'maxAvailableVersion', function () {
      const {
        maxAvailableVersion,
        isNewNodePool,
        nodePool
      } = this;

      if (isNewNodePool && maxAvailableVersion !== nodePool.version) {
        Ember.set(nodePool, 'version', maxAvailableVersion);
      }
    })),
    scopeConfigChanged: Ember.on('init', Ember.observer('scopeConfig', function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      Ember.set(this.nodePool.config, 'oauthScopes', this.google.mapOauthScopes(this.oauthScopesSelection, this.scopeConfig));
    })),
    // In create mode, the cluster version can fallback to the first item in the versionChoices array.
    // Similarly, defaultClusterVersion is used to synchronize the node version.
    defaultClusterVersion: Ember.computed('versionChoices', 'clusterVersion', function () {
      const {
        clusterVersion,
        versionChoices
      } = this;

      if (versionChoices.some(v => (v === null || v === void 0 ? void 0 : v.value) === clusterVersion)) {
        return clusterVersion;
      } else {
        var _versionChoices$;

        return (_versionChoices$ = versionChoices[0]) === null || _versionChoices$ === void 0 ? void 0 : _versionChoices$.value;
      }
    }),
    regionalTotalNodeCounts: Ember.computed('locationType', 'nodePool.initialNodeCount', 'locationContent.@each.checked', function () {
      var _this$nodePool;

      const {
        locationType
      } = this;
      let totalLocations = this.locationContent.filterBy('checked').length;

      if (locationType === 'zonal') {
        // willSave in the cluster will add the selected zone as the default location in the locations array
        totalLocations = totalLocations + 1;
      }

      return (this === null || this === void 0 ? void 0 : (_this$nodePool = this.nodePool) === null || _this$nodePool === void 0 ? void 0 : _this$nodePool.initialNodeCount) * totalLocations;
    }),
    showManagementWarning: Ember.computed('originalCluster.gkeStatus.upstreamSpec.imported', 'nodePool.management.{autoUpgrade,autoRepair}', function () {
      var _originalCluster$gkeS, _originalCluster$gkeS2, _nodePool$management, _nodePool$management2;

      const {
        nodePool,
        originalCluster
      } = this;
      const isClusterImported = !Ember.isEmpty(originalCluster) && (originalCluster === null || originalCluster === void 0 ? void 0 : (_originalCluster$gkeS = originalCluster.gkeStatus) === null || _originalCluster$gkeS === void 0 ? void 0 : (_originalCluster$gkeS2 = _originalCluster$gkeS.upstreamSpec) === null || _originalCluster$gkeS2 === void 0 ? void 0 : _originalCluster$gkeS2.imported);

      if (isClusterImported && (!(nodePool !== null && nodePool !== void 0 && (_nodePool$management = nodePool.management) !== null && _nodePool$management !== void 0 && _nodePool$management.autoRepair) || !(nodePool !== null && nodePool !== void 0 && (_nodePool$management2 = nodePool.management) !== null && _nodePool$management2 !== void 0 && _nodePool$management2.autoUpgrade))) {
        return true;
      }

      return false;
    }),
    originalClusterVersion: Ember.computed('originalCluster.gkeConfig.kubernetesVersion', 'originalCluster.gkeStatus.upstreamSpec.kubernetesVersion', function () {
      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.gkeConfig.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.gkeConfig.kubernetesVersion');
      }

      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.gkeStatus.upstreamSpec.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.gkeStatus.upstreamSpec.kubernetesVersion');
      }

      return '';
    }),
    clusterWillUpgrade: Ember.computed('clusterVersion', 'originalClusterVersion', function () {
      const {
        clusterVersion,
        originalClusterVersion
      } = this;
      return !!clusterVersion && !!originalClusterVersion && clusterVersion !== originalClusterVersion;
    }),
    isNewNodePool: Ember.computed('nodePool.isNew', function () {
      var _this$nodePool2;

      return this !== null && this !== void 0 && (_this$nodePool2 = this.nodePool) !== null && _this$nodePool2 !== void 0 && _this$nodePool2.isNew ? true : false;
    }),

    /**
     * This property is used to show/hide a np version upgrade checkbox
     * when the box is checked the np is upgraded to latest node version that is <= cp version
     * with new node pools, the version is always kept in sync with the cp version so no checkbox shown
     */
    upgradeAvailable: Ember.computed('isNewNodePool', 'clusterWillUpgrade', function () {
      const {
        isNewNodePool,
        clusterWillUpgrade
      } = this;
      return !isNewNodePool && clusterWillUpgrade;
    }),
    // GCP api provides a separate list of versions for node pools, which can be upgraded to anything <= control plane version
    maxAvailableVersion: Ember.computed('clusterVersion', 'nodeVersions.[]', function () {
      const {
        clusterVersion,
        nodeVersions
      } = this;
      const availableVersions = nodeVersions.filter(nv => {
        try {
          const lteCP = _semver.default.lte(nv, clusterVersion, {
            includePreRelease: true
          });

          return lteCP;
        } catch {
          return;
        }
      });
      return availableVersions[0];
    }),
    editedMachineChoice: Ember.computed('nodePool.config.machineType', 'machineChoices', function () {
      return Ember.get(this, 'machineChoices').findBy('name', Ember.get(this, 'nodePool.config.machineType'));
    }),
    machineChoices: Ember.computed('machineTypes.[]', function () {
      let out = (Ember.get(this, 'machineTypes') || []).slice();
      out.forEach(obj => {
        Ember.setProperties(obj, {
          displayName: `${obj.name} (${obj.description})`,
          group: obj.name.split('-')[0],
          sortName: (0, _util.sortableNumericSuffix)(obj.name)
        });
      });
      return out.sortBy('sortName');
    })
  });

  _exports.default = _default;
});