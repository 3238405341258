define("shared/components/managed-import-cluster-info/component", ["exports", "shared/components/managed-import-cluster-info/template", "semver", "shared/utils/parse-version", "shared/utils/sort", "shared/utils/constants"], function (_exports, _template, _semver, _parseVersion, _sort, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    releaseVersions: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    editing: false,
    configField: 'k3sConfig',
    initialKubernetesVersion: null,
    supportedK8sVersionRange: Ember.computed.alias(`settings.${_constants.default.SETTING.VERSION_K8S_SUPPORTED_RANGE}`),

    init() {
      this._super(...arguments);

      this.set('initialKubernetesVersion', Ember.get(this, `config.kubernetesVersion`));
    },

    showKubernetesVersionBanner: Ember.computed('config.kubernetesVersion', 'initialKubernetesVersion', function () {
      const initialVersion = Ember.get(this, 'initialKubernetesVersion');
      const selectedVersion = Ember.get(this, 'config.kubernetesVersion');

      const initialIsLt127 = initialVersion && _semver.default.lt(initialVersion, '1.27.0');

      const selectedIsGte127 = selectedVersion && _semver.default.gte(selectedVersion, '1.27.0');

      return initialIsLt127 && selectedIsGte127;
    }),
    config: Ember.computed('cluster.{k3sConfig,rke2Config}', 'configField', function () {
      return Ember.get(this, `cluster.${this.configField}`);
    }),
    showAce: Ember.computed('cluster.{internal,k3sConfig,rancherKubernetesEngineConfig,rke2Config,rke2config}', function () {
      // Hide on the local cluster
      if (Ember.get(this, 'cluster.internal')) {
        return false;
      }

      return !!Ember.get(this, 'cluster.rancherKubernetesEngineConfig') || !!Ember.get(this, 'cluster.k3sConfig') || !!Ember.get(this, 'cluster.rke2Config') || false;
    }),
    upgradeStrategy: Ember.computed('config.{k3supgradeStrategy,rke2upgradeStrategy}', 'configField', function () {
      const {
        configField
      } = this;
      const upgradeStrategyPath = configField === 'k3sConfig' ? 'k3supgradeStrategy' : 'rke2upgradeStrategy';
      return Ember.get(this.config, upgradeStrategyPath);
    }),
    serverConcurrency: Ember.computed('upgradeStrategy.serverConcurrency', {
      get() {
        return Ember.get(this, 'upgradeStrategy.serverConcurrency');
      },

      set(key, value) {
        Ember.set(this, 'upgradeStrategy.serverConcurrency', this.coerceToInt(value));
        return value;
      }

    }),
    workerConcurrency: Ember.computed('upgradeStrategy.workerConcurrency', {
      get() {
        return Ember.get(this, 'upgradeStrategy.workerConcurrency');
      },

      set(key, value) {
        Ember.set(this, 'upgradeStrategy.workerConcurrency', this.coerceToInt(value));
        return value;
      }

    }),
    allVersions: Ember.computed('releaseVersions.allVersions.[]', 'supportedK8sVersionRange', function () {
      let currentVersion = Ember.get(this, `config.kubernetesVersion`);
      const versionsMapped = [];
      let allVersions = this.releaseVersions.allVersions || [];

      if (!allVersions.includes(currentVersion)) {
        allVersions.unshift(currentVersion);
      }

      allVersions = [...(0, _sort.sortVersions)(allVersions).reverse()];
      allVersions.forEach(v => {
        let experimental = false;

        if (this.supportedK8sVersionRange) {
          experimental = !(0, _parseVersion.satisfies)(v, this.supportedK8sVersionRange);
        }

        if (_semver.default.gte(v, currentVersion)) {
          versionsMapped.pushObject({
            value: v,
            label: `${v} ${experimental ? this.intl.t('generic.experimental') : ''}`
          });
        }
      });

      const includeOnlyLatestMinorVersions = () => {
        let result = [];
        let minorVersions = {};

        for (let i = 0; i < versionsMapped.length; i++) {
          const version = versionsMapped[i].value;
          const versionPieces = version.split('.');
          const minorVersion = versionPieces[0] + versionPieces[1];

          if (!minorVersions[minorVersion] || (0, _parseVersion.compare)(minorVersions[minorVersion].value, version) < 0) {
            minorVersions[minorVersion] = versionsMapped[i];
          }
        }

        for (const minorVersion in minorVersions) {
          // Don't add the version if it would
          // duplicate the current version
          if (minorVersions[minorVersion].value !== currentVersion) {
            result.push(minorVersions[minorVersion]);
          }
        } // Always include the current version so that
        // it shows as the default value in the form


        result.push({
          label: currentVersion,
          value: currentVersion
        });
        return result;
      };

      const onlyLatestMinorVersions = includeOnlyLatestMinorVersions();
      return onlyLatestMinorVersions;
    }),

    coerceToInt(value) {
      let errors;
      let coerced = value;
      errors = Ember.set(this, 'errors', []);
      coerced = parseInt(value, 10);

      if (isNaN(coerced)) {
        errors.push(this.intl.t('managedImportClusterInfo.error.int'));
        Ember.set(this, 'errors', errors);
        return value;
      }

      return coerced;
    }

  });

  _exports.default = _default;
});