define("shared/utils/amazon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.tagsFromResource = _exports.nameFromResource = _exports.EKS_VERSIONS = _exports.EKS_REGIONS = _exports.REGIONS = _exports.INSTANCE_TYPES = void 0;
  const INSTANCE_TYPES = [{
    group: 'T2 - Burstable',
    name: 't2.nano'
  }, {
    group: 'T2 - Burstable',
    name: 't2.micro'
  }, {
    group: 'T2 - Burstable',
    name: 't2.small'
  }, {
    group: 'T2 - Burstable',
    name: 't2.medium'
  }, {
    group: 'T2 - Burstable',
    name: 't2.large'
  }, {
    group: 'T2 - Burstable',
    name: 't2.xlarge'
  }, {
    group: 'T2 - Burstable',
    name: 't2.2xlarge'
  }, {
    group: 'T3 - Burstable',
    name: 't3.nano'
  }, {
    group: 'T3 - Burstable',
    name: 't3.micro'
  }, {
    group: 'T3 - Burstable',
    name: 't3.small'
  }, {
    group: 'T3 - Burstable',
    name: 't3.medium'
  }, {
    group: 'T3 - Burstable',
    name: 't3.large'
  }, {
    group: 'T3 - Burstable',
    name: 't3.xlarge'
  }, {
    group: 'T3 - Burstable',
    name: 't3.2xlarge'
  }, {
    group: 'T3a - Burstable',
    name: 't3a.nano'
  }, {
    group: 'T3a - Burstable',
    name: 't3a.micro'
  }, {
    group: 'T3a - Burstable',
    name: 't3a.small'
  }, {
    group: 'T3a - Burstable',
    name: 't3a.medium'
  }, {
    group: 'T3a - Burstable',
    name: 't3a.large'
  }, {
    group: 'T3a - Burstable',
    name: 't3a.xlarge'
  }, {
    group: 'T3a - Burstable',
    name: 't3a.2xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.large'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.2xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.4xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.8xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.12xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.16xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.24xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.32xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.48xlarge'
  }, {
    group: 'M6a - General Purpose (AMD)',
    name: 'm6a.metal'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.medium'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.large'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.xlarge'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.2xlarge'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.4xlarge'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.8xlarge'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.12xlarge'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.16xlarge'
  }, {
    group: 'M6g - General Purpose (ARM)',
    name: 'm6g.metal'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.medium'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.large'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.xlarge'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.2xlarge'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.4xlarge'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.8xlarge'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.12xlarge'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.16xlarge'
  }, {
    group: 'M6gd - General Purpose (ARM, Local SSD)',
    name: 'm6gd.metal'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.large'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.2xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.4xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.8xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.12xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.16xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.24xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.32xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6i.metal'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.large'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.2xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.4xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.8xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.12xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.16xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.24xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.32xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6id.metal'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.large'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.2xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.4xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.8xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.12xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.16xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.24xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.32xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6idn.metal'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.large'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.2xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.4xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.8xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.12xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.16xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.24xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.32xlarge'
  }, {
    group: 'M6i - General Purpose (Intel)',
    name: 'm6in.metal'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.large'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.2xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.4xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.8xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.12xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.16xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.24xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5.metal'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.large'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.2xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.4xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.8xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.12xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.16xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.24xlarge'
  }, {
    group: 'M5 - General Purpose',
    name: 'm5d.metal'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5a.large'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5a.xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5a.2xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5a.4xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5a.8xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5a.12xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5a.16xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5a.24xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5ad.large'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5ad.xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5ad.2xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5ad.4xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5ad.12xlarge'
  }, {
    group: 'M5a - General Purpose',
    name: 'm5ad.24xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.large'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.2xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.4xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.10xlarge'
  }, {
    group: 'M4 - General Purpose',
    name: 'm4.16xlarge'
  }, {
    group: 'M3 - General Purpose',
    name: 'm3.medium'
  }, {
    group: 'M3 - General Purpose',
    name: 'm3.large'
  }, {
    group: 'M3 - General Purpose',
    name: 'm3.xlarge'
  }, {
    group: 'M3 - General Purpose',
    name: 'm3.2xlarge'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.medium'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.large'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.xlarge'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.2xlarge'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.4xlarge'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.8xlarge'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.12xlarge'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.16xlarge'
  }, {
    group: 'C6g - High CPU (ARM)',
    name: 'c6g.metal'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.medium'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.large'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.xlarge'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.2xlarge'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.4xlarge'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.8xlarge'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.12xlarge'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.16xlarge'
  }, {
    group: 'C6gd - High CPU (ARM, Local SSD)',
    name: 'c6gd.metal'
  }, {
    group: 'C6gn - High CPU (ARM)',
    name: 'c6gn.medium'
  }, {
    group: 'C6gn - High CPU (ARM)',
    name: 'c6gn.large'
  }, {
    group: 'C6gn - High CPU (ARM)',
    name: 'c6gn.xlarge'
  }, {
    group: 'C6gn - High CPU (ARM)',
    name: 'c6gn.2xlarge'
  }, {
    group: 'C6gn - High CPU (ARM)',
    name: 'c6gn.4xlarge'
  }, {
    group: 'C6gn - High CPU (ARM)',
    name: 'c6gn.8xlarge'
  }, {
    group: 'C6gn - High CPU (ARM)',
    name: 'c6gn.12xlarge'
  }, {
    group: 'C6gn - High CPU (ARM)',
    name: 'c6gn.16xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.large'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.2xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.4xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.8xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.12xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.16xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.24xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.32xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6i.metal'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.large'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.2xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.4xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.8xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.12xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.16xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.24xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.32xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.48xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6a.metal'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.large'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.2xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.4xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.8xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.12xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.16xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.24xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.32xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6id.metal'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.large'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.2xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.4xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.8xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.12xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.16xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.24xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.32xlarge'
  }, {
    group: 'C6i - High-CPU (Intel)',
    name: 'c6in.metal'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.large'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.2xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.4xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.9xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.12xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.18xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.24xlarge'
  }, {
    group: 'C5 - High CPU',
    name: 'c5.metal'
  }, {
    group: 'C5a - High CPU',
    name: 'c5a.large'
  }, {
    group: 'C5a - High CPU',
    name: 'c5a.xlarge'
  }, {
    group: 'C5a - High CPU',
    name: 'c5a.2xlarge'
  }, {
    group: 'C5a - High CPU',
    name: 'c5a.4xlarge'
  }, {
    group: 'C5a - High CPU',
    name: 'c5a.8xlarge'
  }, {
    group: 'C5a - High CPU',
    name: 'c5a.12xlarge'
  }, {
    group: 'C5a - High CPU',
    name: 'c5a.16xlarge'
  }, {
    group: 'C5a - High CPU',
    name: 'c5a.24xlarge'
  }, {
    group: 'C5D - High CPU',
    name: 'c5d.large'
  }, {
    group: 'C5D - High CPU',
    name: 'c5d.xlarge'
  }, {
    group: 'C5D - High CPU',
    name: 'c5d.2xlarge'
  }, {
    group: 'C5D - High CPU',
    name: 'c5d.4xlarge'
  }, {
    group: 'C5D - High CPU',
    name: 'c5d.9xlarge'
  }, {
    group: 'C5D - High CPU',
    name: 'c5d.18xlarge'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.large'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.xlarge'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.2xlarge'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.4xlarge'
  }, {
    group: 'C4 - High CPU',
    name: 'c4.8xlarge'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.large'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.xlarge'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.2xlarge'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.4xlarge'
  }, {
    group: 'C3 - High CPU',
    name: 'c3.8xlarge'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.large'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.xlarge'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.2xlarge'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.4xlarge'
  }, {
    group: 'R3 - High Memory',
    name: 'r3.8xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.large'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.2xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.4xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.8xlarge'
  }, {
    group: 'R4 - High Memory',
    name: 'r4.16xlarge'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.large'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.xlarge'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.2xlarge'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.4xlarge'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.12xlarge	'
  }, {
    group: 'R5 - High Memory Optimized',
    name: 'r5.24xlarge	'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.large'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.xlarge'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.2xlarge'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.4xlarge'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.12xlarge'
  }, {
    group: 'R5D - High Memory Optimized & Local Storage',
    name: 'r5d.24xlarge'
  }, {
    group: 'D2 - High Density Storage',
    name: 'd2.xlarge'
  }, {
    group: 'D2 - High Density Storage',
    name: 'd2.2xlarge'
  }, {
    group: 'D2 - High Density Storage',
    name: 'd2.4xlarge'
  }, {
    group: 'D2 - High Density Storage',
    name: 'd2.8xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.large'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.2xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.4xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.8xlarge'
  }, {
    group: 'I3 - High I/O Storage',
    name: 'i3.16xlarge'
  }, {
    group: 'F1 - FPGA',
    name: 'f1.2xlarge'
  }, {
    group: 'F1 - FPGA',
    name: 'f1.16xlarge'
  }, {
    group: 'G3 - GPU',
    name: 'g3.4xlarge'
  }, {
    group: 'G3 - GPU',
    name: 'g3.8xlarge'
  }, {
    group: 'G3 - GPU',
    name: 'g3.16xlarge'
  }, {
    group: 'G4dn - GPU',
    name: 'g4dn.xlarge'
  }, {
    group: 'G4dn - GPU',
    name: 'g4dn.2xlarge'
  }, {
    group: 'G4dn - GPU',
    name: 'g4dn.4xlarge'
  }, {
    group: 'G4dn - GPU',
    name: 'g4dn.8xlarge'
  }, {
    group: 'G4dn - GPU',
    name: 'g4dn.12xlarge'
  }, {
    group: 'G4dn - GPU',
    name: 'g4dn.16xlarge'
  }, {
    group: 'G4dn - GPU',
    name: 'g4dn.metal'
  }, {
    group: 'P2 - GPU',
    name: 'p2.xlarge'
  }, {
    group: 'P2 - GPU',
    name: 'p2.8xlarge'
  }, {
    group: 'P2 - GPU',
    name: 'p2.16xlarge'
  }, {
    group: 'P3 - GPU',
    name: 'p3.2xlarge'
  }, {
    group: 'P3 - GPU',
    name: 'p3.8xlarge'
  }, {
    group: 'P3 - GPU',
    name: 'p3.16xlarge'
  }, {
    group: 'P3 - GPU',
    name: 'p3dn.24xlarge'
  }, {
    group: 'X1 - Really High Memory',
    name: 'x1.16xlarge'
  }, {
    group: 'X1 - Really High Memory',
    name: 'x1.32xlarge'
  }]; // Supports
  // 1) Validating AWS creds (used in both EC2 and EKS world)
  // 2) Managing EC2 Nodes. See https://cloud-images.ubuntu.com/locator/ec2/ (and https://github.com/rancher/machine/blob/master/drivers/amazonec2/region.go#L13)

  _exports.INSTANCE_TYPES = INSTANCE_TYPES;
  const REGIONS = ['af-south-1', 'ap-northeast-1', 'ap-northeast-2', 'ap-southeast-1', 'ap-southeast-2', 'ap-southeast-3', 'ap-southeast-4', 'ap-east-1', 'ap-south-1', 'ap-south-2', 'me-south-1', 'me-central-1', 'ca-central-1', 'cn-north-1', 'cn-northwest-1', 'eu-north-1', 'eu-west-1', 'eu-west-2', 'eu-west-3', 'eu-central-1', 'eu-central-2', 'eu-south-1', 'eu-south-2', 'sa-east-1', 'us-east-1', 'us-east-2', 'us-west-1', 'us-west-2', 'us-gov-west-1', 'us-gov-east-1']; // Supports
  // 1) Managing EKS clusters. See https://docs.aws.amazon.com/general/latest/gr/eks.html

  _exports.REGIONS = REGIONS;
  const EKS_REGIONS = ['af-south-1', 'ap-northeast-1', 'ap-northeast-2', 'ap-northeast-3', 'ap-southeast-1', 'ap-southeast-2', 'ap-southeast-3', 'ap-southeast-4', 'ap-east-1', 'ap-south-1', 'ap-south-2', 'me-south-1', 'me-central-1', 'ca-central-1', 'cn-north-1', 'cn-northwest-1', 'eu-north-1', 'eu-west-1', 'eu-west-2', 'eu-west-3', 'eu-central-1', 'eu-central-2', 'eu-south-1', 'eu-south-2', 'sa-east-1', 'us-east-1', 'us-east-2', 'us-west-1', 'us-west-2', 'us-gov-west-1', 'us-gov-east-1']; // from https://docs.aws.amazon.com/eks/latest/userguide/kubernetes-versions.html

  _exports.EKS_REGIONS = EKS_REGIONS;
  const EKS_VERSIONS = ['1.28', '1.27', '1.26', '1.25']; // sort newest->oldest so we dont have to run any logic to sort like other provider versions

  _exports.EKS_VERSIONS = EKS_VERSIONS;

  const nameFromResource = function (r, idField) {
    let id = r[idField];
    let out = id;

    if (r && r.Tags && r.Tags.length) {
      let match = r.Tags.filterBy('Key', 'Name')[0];

      if (match) {
        out = `${match.Value} (${id})`;
      }
    }

    return out;
  };

  _exports.nameFromResource = nameFromResource;

  const tagsFromResource = function (r) {
    let out = [];

    if (r && r.Tags && r.Tags.length) {
      r.Tags.forEach(tag => {
        if (tag.Key !== 'Name') {
          out.push(`${tag.Key}=${tag.Value}`);
        }
      });
    }

    return out;
  };

  _exports.tagsFromResource = tagsFromResource;
  var _default = {
    instanceTypes: INSTANCE_TYPES,
    nameFromResource,
    tagsFromResource
  };
  _exports.default = _default;
});